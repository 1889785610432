var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-check-item"
  }, [_c('van-cell', {
    staticClass: "bill-check-cell",
    attrs: {
      "is-link": "",
      "center": "",
      "title": _vm.bill.user.nickname
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('to-detail-view', _vm.bill);
      }
    }
  }, [_vm.bill.status === 0 ? _c('van-tag', {
    staticClass: "bill-check-cell__tag",
    attrs: {
      "type": "warning"
    }
  }, [_vm._v("未完成")]) : _c('van-tag', {
    staticClass: "bill-check-cell__tag",
    attrs: {
      "type": "success"
    }
  }, [_vm._v("已完成")])], 1), _vm.bill.status === 0 && _vm.bill.check_msg ? _c('van-cell', {
    attrs: {
      "title": "系统消息",
      "value": _vm.bill.check_msg
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "账单日期",
      "value": _vm.bill.bill_date
    }
  }), _c('van-cell', {
    attrs: {
      "title": "交账时间",
      "value": _vm.bill.created_at
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "amount-color",
      "title": "订单金额",
      "value": "\uFFE5".concat(_vm.bill.amount)
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "amount-color",
      "title": "结算金额",
      "value": "\uFFE5".concat(_vm.bill.real_amount)
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }