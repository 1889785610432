<template>
  <div class="bill-check-container">
    <my-nav-bar
      title="账单审核"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-tabs
        v-model="active"
        color="#1A6FF2"
        title-active-color="#1A6FF2"
        class="search-bar"
        @change="activeChange"
      >
        <van-tab title="配送账单" :name="2" :badge="deliveryNotCheckCount > 0 ? deliveryNotCheckCount : ''" />
        <van-tab title="门店账单" :name="1" :badge="storeNotCheckCount > 0 ? storeNotCheckCount : ''" />
      </van-tabs>
      <van-search
        v-model="search_key"
        placeholder="请输入司机名"
        shape="round"
        class="search-bar2"
        show-action
      >
        <div slot="action" @click="getBills('search')">搜索</div>
      </van-search>
      <div ref="billContent" class="bill-check-content">
        <van-list
          ref="list"
          v-model="loading"
          :finished="finished"
          class="list"
          @load="getBills"
        >
          <van-empty v-if="showEmpty" description="暂无数据" />
          <div v-for="(bill, index) in bills" :key="index" class="bill-check-item-container">
            <bill-check-item :bill="bill" @to-detail-view="toDetailView" />
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import billCheckApi from '@/api/bill_check'
import myNavBar from '../../components/my-nav-bar'
import BillCheckItem from './components/bill-check-item'
export default {
  name: 'BillCheck',
  components: { myNavBar, BillCheckItem },
  data() {
    return {
      bills: [],
      active: 2,
      storeNotCheckCount: 0,
      deliveryNotCheckCount: 0,
      page: 0,
      limit: 10,
      loading: false,
      finished: false,
      showEmpty: false,
      search_key: ''
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    this.$eventBus.$on('bill-finished', () => {
      this.reload()
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/bill-check-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      this.$eventBus.$off('bill-finished')
    }

    next()
  },
  methods: {
    reload() {
      this.bills = []
      this.page = 0
      this.finished = false
      this.showEmpty = false
      this.$nextTick(() => {
        this.$refs.list.check()
      })
    },
    getBills(type = '') {
      if (type === 'search') {
        this.activeChange()
      }
      this.page++
      const params = {
        page: this.page,
        limit: this.limit,
        type: this.active,
        deliver_name: this.search_key
      }
      billCheckApi.list(params).then(res => {
        this.bills = this.bills.concat(res.data.list)
        this.storeNotCheckCount = res.data.store_not_check_count
        this.deliveryNotCheckCount = res.data.delivery_not_check_count
        this.loading = false
        if (res.data.list.length < this.limit) { this.finished = true }
        this.showEmpty = this.bills.length === 0
      })
    },
    toDetailView(bill) {
      this.$router.push({
        path: '/bill-check-detail',
        query: {
          bill_id: bill.id
        }
      })
    },
    activeChange() {
      this.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
    margin-top: 145px;
  }
  .bill-check-item-container {
    margin-bottom: 10px;
  }
  .bill-check-item-container:nth-last-child(2) {
    margin-bottom: 0;
  }
</style>
