import request from '@/utils/request'

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'bill-check',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'bill-check/detail',
      params
    })
  },
  unpaidOrder(params) {
    return request({
      method: 'get',
      url: 'bill-check/unpaid-order',
      params
    })
  }
}

export function check(data) {
  return request({
    method: 'post',
    url: 'bill-check/check',
    data
  })
}

// 获取订单明细
export function getOrders(params) {
  return request({
    method: 'get',
    url: 'bill-check/order',
    params
  })
}

// 获取订单商品明细
export function getOrderGoodsList(params) {
  return request({
    method: 'get',
    url: 'bill-check/order/goods',
    params
  })
}

// 获取退货单明细
export function getAfterOrders(params) {
  return request({
    method: 'get',
    url: 'bill-check/after-order',
    params
  })
}

// 获取退货单商品
export function getAfterGoodsList(params) {
  return request({
    method: 'get',
    url: 'bill-check/after-order/goods',
    params
  })
}

export function getPaymentOnlines(params) {
  return request({
    method: 'get',
    url: 'bill-check/payment-online',
    params
  })
}

export function modifyBillDate(data) {
  return request({
    method: 'post',
    url: 'bill-check/modify-date',
    data
  })
}

export function modifyPayType(data) {
  return request({
    method: 'post',
    url: 'bill-check/paytype/modify',
    data
  })
}
