<template>
  <div class="bill-check-item">
    <van-cell
      class="bill-check-cell"
      is-link
      center
      :title="bill.user.nickname"
      @click="$emit('to-detail-view', bill)"
    >
      <van-tag v-if="bill.status === 0" class="bill-check-cell__tag" type="warning">未完成</van-tag>
      <van-tag v-else class="bill-check-cell__tag" type="success">已完成</van-tag>
    </van-cell>
    <van-cell v-if="bill.status === 0 && bill.check_msg" title="系统消息" :value="bill.check_msg" />
    <van-cell title="账单日期" :value="bill.bill_date" />
    <van-cell title="交账时间" :value="bill.created_at" />
    <van-cell value-class="amount-color" title="订单金额" :value="`￥${bill.amount}`" />
    <van-cell value-class="amount-color" title="结算金额" :value="`￥${bill.real_amount}`" />
  </div>
</template>

<script>
export default {
  props: {
    bill: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
